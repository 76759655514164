import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Get Started",
  "path": "/docs/get-started",
  "date": "2020-03-12T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Get started`}</h1>
    <p>{`enableHR uses the OAuth 2.0 authorization code flow to issue API access tokens on behalf of users. Please get an understanding of OAuth 2.0 from `}<a parentName="p" {...{
        "href": "https://oauth.net"
      }}>{`https://oauth.net`}</a>{` before proceeding if you are not familiar with the protocols.`}</p>
    <p>{`Before you can start using the enableHR API you need to get the client credentials for your application.`}</p>
    <h2>{`1. Get client credentials for your application`}</h2>
    <p>{`At this point you will have to contact enableHR via `}<a href="mailto:api@enablehr.com">{`email`}</a>{` or phone to get the client credentials for your application`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`redirect_uri`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`this is the callback URL within your application that will be called after the user has authenticated and authorized access to your application`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`client_id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`provided by enablehr once you provide use the redirect_uri`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`client_secret`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`provided by enablehr once provide the redirect_uri`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`enableHR will provide you the client_id and client_secret via an email or SMS`}</p>
    <p>{`Keep the client secret safe as this is your password to access our API's`}</p>
    <h2>{`API`}</h2>
    <p>{`enablehr uses the OpenAPI specifications and uses OpenAPI 3.0 to specify our API.`}</p>
    <h2>{`API Specifications`}</h2>
    <p>{`enableHR API specifications can be found at `}<a parentName="p" {...{
        "href": "https://rest.enablehr.com/enablehr.yaml"
      }}>{`https://rest.enablehr.com/enablehr.yaml`}</a>{` OR `}<a parentName="p" {...{
        "href": "https://rest.enablehr.com/enablehr.json"
      }}>{`https://rest.enablehr.com/enablehr.json`}</a>{`.`}</p>
    <h2>{`Language Seleciion`}</h2>
    <p>{`OpenAPI tools allow you to program in any language of your choice. OpenAPI offers tools to allow you to generate code from the OpenAPI specifications. Once you have selected your language the next step will show you how to generate the code in your selected language`}</p>
    <h2>{`Client code generation in your chosen language`}</h2>
    <p>{`The following steps are using the online editor `}<a parentName="p" {...{
        "href": "http://editor.swagger.io"
      }}>{`http://editor.swagger.io`}</a>{` There are build tools available from OpenAPITools that can be incorporated into a build process.`}</p>
    <ol>
      <li parentName="ol">{`Open your browser`}</li>
      <li parentName="ol">{`Navigate to `}<a parentName="li" {...{
          "href": "http://editor.swagger.io"
        }}>{`http://editor.swagger.io`}</a></li>
      <li parentName="ol">{`Copy paste the entire yaml file from `}<a parentName="li" {...{
          "href": "https://rest.enablehr.com/enablehr.yaml"
        }}>{`https://rest.enablehr.com/enablehr.yaml`}</a>{` into the left pane of `}<a parentName="li" {...{
          "href": "http://editor.swagger.io"
        }}>{`http://editor.swagger.io`}</a></li>
      <li parentName="ol">{`From the menu bar Select Generate Client and select the language that your application will use. You will see a download start that will download a ZIP file containing the client code`}</li>
    </ol>
    <h2>{`Compiling client to create libraries for your chosen language`}</h2>
    <p>{`Unzip the file in an appropriate directory and use the README.md file to identify the steps to build the code for accessing the endpoint.`}</p>
    <p>{`To use this code you will need to add additional code to extract the
OAUTH 2.0 token and provide it to each call of the API.`}</p>
    <p>{`Use the instructions found in README.md to compile the libraries`}</p>
    <h2>{`Setting up to build you application`}</h2>
    <p>{`Follow the normal process to build your application using your IDE or your build tools.`}</p>
    <h2>{`Authentication`}</h2>
    <p>{`Please see `}<a parentName="p" {...{
        "href": "/development/authentication",
        "title": "authentication"
      }}>{`authentication`}</a>{` for further details.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      